import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';

const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin: 3rem 0;
`;

export default class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout title="Page Not Found">
        <Title>Page Not Found</Title>
      </Layout>
    );
  }
}
